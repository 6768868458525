import {FC} from "react";
import {Grid} from "@mui/material";
import * as React from "react";

import "./EmptyStateText.scss";
import {EmptyStateTextInterface} from "./EmptyStateTextInterface";

export const EmptyStateText: FC<EmptyStateTextInterface> = ({message}) => {
  return(
    <Grid container style={{backgroundColor: "#00000036"}}>
      <Grid item xs={12} sm={12} md={12} lg={12} className={"empty-state-text"}>
        {message}
      </Grid>
    </Grid>
  );
};
