import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import {PassPortal} from "./pages/PassPortal/PassPortal";
import {FuturisticKid} from "./pages/FuturisticKid/FuturisticKid";


const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="portal-pass" element={<PassPortal />}/>
        <Route path="futuristic-kid" element={<FuturisticKid />}/>
        <Route path="*" element={<Navigate to="portal-pass" />} />
      </Routes>
    </Router>
  );
};

export default App;
