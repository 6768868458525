import React, {FC, useState} from "react";
import {useFuturisticPortalPassMinterContract} from "../../hooks/FuturisticPortalPassMinterContract";
import {usePuppiesContract} from "../../hooks/PuppiesContract";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {SelectedCollectionItem} from "../SelectedItem/SelectedItemProvider";
import {MintButton} from "./MintButton";
import {ContractTransaction} from "ethers";
import {Modal, Typography} from "@mui/material";
import Continue from "../../assets/Continue.png";
import {NOTIFICATION_ACTIONS, NotificationContext} from "../Notification/NotificationProvider";
import {withTransaction, WithTransactionProps} from "../../hoc/WithTransaction";
import {CollectionName} from "@bubblegum/commons/domain/Collection";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  maxHeight: "300px",
  maxWidth: "600px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(56,5,55,0.33)",
  boxShadow: "24",
  pt: 2,
  px: 4,
  pb: 3,
};


export const BasePortalPassMintButton: FC<WithTransactionProps> = ({submitTransaction}) => {

  const [openApprovalModal, setOpenApprovalModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);


  const {dispatchNotification} = React.useContext(NotificationContext);

  const {futuristicPortalPassMinterContract} = useFuturisticPortalPassMinterContract();
  const {puppiesContract} = usePuppiesContract();
  const {address} = useConnectedAddress();


  const getIdsFromSelectedItems = (
    selectedItems: SelectedCollectionItem[]
  ): [number, number, number, number, number] => {
    return [
      // 0,
      // 1,
      // 2,
      // 3,
      // 4,
      selectedItems[0].item.blockchainId,
      selectedItems[1].item.blockchainId,
      selectedItems[2].item.blockchainId,
      selectedItems[3].item.blockchainId,
      selectedItems[4].item.blockchainId
    ];
  };

  const onMint = async (selectedItems: SelectedCollectionItem[]): Promise<ContractTransaction> => {
    return await futuristicPortalPassMinterContract.mint(getIdsFromSelectedItems(selectedItems));
  };

  const areApproved = async (selectedItems: SelectedCollectionItem[]): Promise<boolean> => {
    let hasApproval = true;

    const operator = futuristicPortalPassMinterContract.getAddress();
    const puppiesIds = getIdsFromSelectedItems(selectedItems);

    // Check every token if it's not approved for all
    if(!(await puppiesContract.isApprovedForAll(address, operator))) {
      for (const puppiesId of puppiesIds) {
        const approved = await puppiesContract.getApproved(puppiesId.toString());
        // If one is false then hasApproval is false
        hasApproval &&= approved === operator;
      }
    }

    if(!hasApproval) {
      setOpenApprovalModal(true);
      return false;
    }
    return true;
  };

  async function approve(): Promise<void> {

    try {
      const operator = futuristicPortalPassMinterContract.getAddress();

      const tx = await  puppiesContract.setApprovalForAll(operator);

      setOpenApprovalModal(false);

      await submitTransaction(
        tx,
        dispatchNotification,
        "You are now approved to mint your portal pass"
      );
    }
    catch(error: unknown){
      console.log(error);
      dispatchNotification({
        type: NOTIFICATION_ACTIONS.SHOW,
        props: {
          alertType: "error",
          message: "Something happened while trying to approve tokens",
          display: true,
        }
      });
    }
    finally {
      setLoading(false);
      setOpenApprovalModal(false);
    }
  }

  const generateToolTipText = (selectedItems: SelectedCollectionItem[]): string => {
    return `Select ${5 - selectedItems.length} more Puppies to get a Portal Pass`;
  };

  return (
    <MintButton
      onMint={onMint}
      areApproved={areApproved}
      initialLoading={loading}
      generateToolTipText={generateToolTipText}
      amountOfSelectedItems={5}
      mintedCollectionName={CollectionName.PORTAL_PASSES}
      successMessage="Portal Pass minted successfully!"
    >
      <Modal
        open={openApprovalModal}
        onClose={() => {setOpenApprovalModal(false); setLoading(false);}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{...style, color: "white", position: "absolute"}}>
          <div style={{position: "relative", display: "block"}}>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h1" fontSize="2rem">
                                You need to approve the contract to burn your Puppies
              </Typography>
            </div>
            <div style={{float: "right"}}>
              <img style={{cursor: "pointer"}} alt="Continue" onClick={approve} width={300} src={Continue} />
            </div>
          </div>
        </div>
      </Modal>
    </MintButton>
  );
};

export const PortalPassMintButton = withTransaction(BasePortalPassMintButton);