import {Box, Card, Grid, Modal, Tooltip} from "@mui/material";
import React, {FC, useEffect} from "react";
import {ChooseCollectionItemCardInterface} from "./ChooseCollectionItemCardInterface";
import {CollectionItemCard} from "../CollectionItemCard/CollectionItemCard";
import CardMedia from "@mui/material/CardMedia";
import {CollectionItem} from "@bubblegum/commons/domain/CollectionItem";
import {SELECTED_ITEM_ACTIONS, SelectedItemContext} from "../SelectedItem/SelectedItemProvider";
import {CollectionName} from "@bubblegum/commons/domain/Collection";

const style = {
  width: "60%",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(215,9,212,0.33)",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxWidth: "80%",
  maxHeight: "80%",
  overflowY: "scroll",
  justifyContent: "center"
};

const ChooseCard: FC<{
    onClick(): void,
    collectionItem?: CollectionItem,
    frame: string,
    onHoverFrame: string,
    selectTooltip: string
}> = (
  {onClick, collectionItem, frame, onHoverFrame, selectTooltip}
) => {

  const parseIPFSUrl = (cidUrl: string) => {
    return `https://ipfs.io/${cidUrl.replace("://", "/")}`;
  };

  const imageUrl = collectionItem?.image.includes("ipfs") ?
    parseIPFSUrl(collectionItem.image) :
    collectionItem?.image;

  const displaySelectedCollectionImage = imageUrl ? "block" : "none";

  const displayMedia = () => {
    if(!imageUrl) return;

    // TODO: Change for an isVideo prop
    if(collectionItem.collection.name === CollectionName.PORTAL_PASSES) {
      return  (
        <video
          src={imageUrl}
          style={{
            width: "140px", height: "140px",
            objectFit: "cover", position: "absolute", top: "49px", left: "44px",
            pointerEvents: "none",
            display: displaySelectedCollectionImage
          }}
          autoPlay loop muted
        />
      );
    }

    return (
      <CardMedia
        component="img"
        image={imageUrl}
        style={{
          width: "140px", height: "140px",
          objectFit: "contain", position: "absolute", top: "49px", left: "44px",
          pointerEvents: "none",
          display: displaySelectedCollectionImage
        }}
      />
    );
  };

  return (
    <Tooltip title={collectionItem ? collectionItem.name : selectTooltip} arrow>
      <Card
        style={{background: "transparent", boxShadow: "none", position: "relative", cursor: "pointer"}}
        onClick={onClick}
      >
        <CardMedia
          component="img"
          image={frame}
          style={{width: "225px", height: "225px", objectFit: "contain"}}
          onMouseOver={e => {
            e.currentTarget.src = onHoverFrame;
          }}
          onMouseOut={e => {
            e.currentTarget.src = frame;
          }}
        />
        {displayMedia()}
      </Card>
    </Tooltip>
  );
};


export const ChooseCollectionItemCard: FC<ChooseCollectionItemCardInterface> = ({
  collectionItems,
  cardPosition,
  frameImage,
  onHoverFrameImage,
  chooseFirstItem,
  selectToolTip
}) => {
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<CollectionItem>();
  const {selectedItems, dispatchSelectedItem} = React.useContext(SelectedItemContext);

  useEffect(() => {
    setSelectedItem(
      selectedItems.find((selectedItem) => selectedItem.position === cardPosition)?.item
    );
  }, [selectedItems]);

  useEffect(() => {
    if(chooseFirstItem && !selectedItem && collectionItems.length > 0) {
      setSelectedItem(collectionItems[0]);
      dispatchSelectedItem({
        type: SELECTED_ITEM_ACTIONS.ITEM_SELECTED,
        position: cardPosition,
        item: collectionItems[0]
      });
    }
  }, [selectedItem, chooseFirstItem]);

  return (
    <>
      <ChooseCard
        collectionItem={selectedItem}
        onClick={() => setOpen(true)}
        frame={frameImage}
        onHoverFrame={onHoverFrameImage}
        selectTooltip={selectToolTip}
      />
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
          >
            {collectionItems?.map((collectionItem) => {
              return (
                <Grid
                  key={collectionItem.name}
                  item
                  xs={12} md={6} lg={4}
                  justifyContent="center"
                  display="flex"
                >
                  <CollectionItemCard
                    collectionItem={collectionItem}
                    collectionItemsSelected={selectedItems.map(selectedItems => selectedItems.item)}
                    onClickCardFunction={(collectionItem) => {
                      setOpen(false);
                      dispatchSelectedItem({
                        type: SELECTED_ITEM_ACTIONS.ITEM_SELECTED,
                        position: cardPosition,
                        item: collectionItem
                      });
                      return true;
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
    </>
  );

};