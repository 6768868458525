import {useEffect, useState} from "react";
import {useSigner} from "wagmi";
import {FuturisticPortalPasses} from "@bubblegum/commons/contracts/FuturisticPortalPasses";

export function useFuturisticPortalPassesContract() {
  const {data: signer} = useSigner();
  const [
    futuristicPortalPassesContract,
    setFuturisticPortalPassesContract
  ] = useState<FuturisticPortalPasses>();

  useEffect(() => {
    if(!signer) return;

    FuturisticPortalPasses.getContract(signer).then(futuristicPortalPassesContract => {
      setFuturisticPortalPassesContract(futuristicPortalPassesContract);
    });

  }, [signer]);

  return {futuristicPortalPassesContract};

}