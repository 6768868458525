import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import Logo from "../../assets/Logo.png";
import LogoHover from "../../assets/LogoHover.png";
import ViewMyCollection from "../../assets/ViewMyCollectionButton.png";
import ViewMyCollectionHover from "../../assets/ViewMyCollectionButtonHover.png";
import ToFuturisticKids from "../../assets/ToFuturisticKids.png";
import ToFuturisticKidsHover from "../../assets/ToFuturisticKidsHover.png";
import ToPortalPasses from "../../assets/ToPortalPasses.png";
import ToPortalPassesHover from "../../assets/ToPortalPassesHover.png";

import "./NavBar.scss";
import {Grid} from "@mui/material";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {useNetwork} from "wagmi";
import {FC} from "react";
import {NavBarInterface} from "./NavBarInterface";
import {Link} from "react-router-dom";


export const NavBar: FC<NavBarInterface> = ({collectionInfo}) => {

  const {address} = useConnectedAddress();
  const { chain } = useNetwork();

  const openSeaMap: {[key in number]: string} = {
    31337: `https://testnets.opensea.io/<address>?search[collections][0]=${collectionInfo.openSeaCollection}`,
    1: `https://opensea.io/<address>?search[collections][0]=${collectionInfo.openSeaCollection}`,
    5: `https://testnets.opensea.io/<address>?search[collections][0]=${collectionInfo.openSeaCollection}`
  };

  const renderLinkToOtherMint = () => {
    let link = "/futuristic-kid";
    let text = "To Futuristic";
    let image = ToFuturisticKids;
    let imageHoover = ToFuturisticKidsHover;
    if(window.location.pathname === link) {
      link = "/portal-pass";
      text = "To Portal Pass";
      image = ToPortalPasses;
      imageHoover = ToPortalPassesHover;
    }

    return <Link
      to={link}
      style={{
        maxHeight: "4rem",
        margin: "auto",
        marginLeft: "4rem",
        cursor: "pointer",
        textDecoration: "none",
        color: "white"
      }}
    >
      <img
        src={image} alt={text} style={{maxHeight: "4rem", margin: "auto", cursor: "pointer"}}
        onMouseOver={e => {
          e.currentTarget.src = imageHoover;
        }}
        onMouseOut={e => {
          e.currentTarget.src = image;
        }}
      />
    </Link>;
  };


  return (
    <AppBar position="static" style={{backgroundColor: "transparent", boxShadow: "none"}}>
      <Container maxWidth="xl" style={{margin: "10px"}}>
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box>

              <img
                src={Logo} alt="Bubblegum Logo" style={{maxHeight: "4rem", margin: "auto", cursor: "pointer"}}
                onClick={() => location.reload()}
                onMouseOver={e => {
                  e.currentTarget.src = LogoHover;
                }}
                onMouseOut={e => {
                  e.currentTarget.src = Logo;
                }}
              />

              {address && chain && <img src={ViewMyCollection} alt="View my collection"
                style={{maxHeight: "4rem", margin: "auto", marginLeft: "4rem", cursor: "pointer"}}
                onMouseOver={e => {
                  e.currentTarget.src = ViewMyCollectionHover;
                }}
                onMouseOut={e => {
                  e.currentTarget.src = ViewMyCollection;
                }}
                onClick={() => window.open(
                  openSeaMap[chain.id].replace("<address>", address),
                  "_blank"
                ).focus()}
              />}
              {renderLinkToOtherMint()}
            </Box>
            <Box>
              <ConnectButton
                chainStatus={"none"}
                showBalance={false}
              />
            </Box>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default NavBar;