import { CollectionName } from "../../domain/Collection";
export var SUPPORTED_COLLECTIONS = [
    {
        name: CollectionName.PUPPIES,
        metadataUrl: "https://ipfs.io/ipfs/QmX1uEQnEPh9HNF4jMfmbYBj93njRSPc5uKfqNu8SKR9hr/{id}.json",
        openSeaCollection: "bubblegumpuppies",
        id: 1,
        contractInfo: {
            31337: { address: "0x1cdd3d5898737b2c6e8132b29f5aed23731103d0" },
            5: { address: "0x1cdd3d5898737b2c6e8132b29f5aed23731103d0" },
            1: { address: "0x86e9c5ad3d4b5519da2d2c19f5c71baa5ef40933" }
        }
    },
    {
        name: CollectionName.PORTAL_PASSES,
        metadataUrl: "https://ipfs.io/ipfs/Qmeq7YLuuQDMuVjbUdNZaqZWJgk4ZNemw337mT7tXBWYAU/{id}.json",
        openSeaCollection: "futuristic-portal-passes",
        id: 2,
        contractInfo: {
            31337: { address: "0xF39312C8d5C9ba437e6a343791055782C6C4Ec26" },
            5: { address: "0xF39312C8d5C9ba437e6a343791055782C6C4Ec26" },
            1: { address: "0x8DBDfdb81a41cea4a948C85b3640A60c9d393abC" }
        }
    },
    {
        name: CollectionName.KIDS,
        metadataUrl: "https://ipfs.io/ipfs/QmTowf8X47VFkWzkgWZAhuL8sLEPkpzmHhcXSa3geScdba/{id}.json",
        openSeaCollection: "bubblegumkids",
        id: 3,
        contractInfo: {
            31337: { address: "0xECa740C849eA7fA3f10b348bfc26570487124f5D" },
            5: { address: "0xECa740C849eA7fA3f10b348bfc26570487124f5D" },
            1: { address: "0xa5ae87b40076745895bb7387011ca8de5fde37e0" }
        }
    },
    {
        name: CollectionName.FUTURISTIC_KIDS,
        metadataUrl: "https://d2mt8lfxryltgv.cloudfront.net/metadata/{id}",
        openSeaCollection: "futuristic-kids",
        id: 4,
        contractInfo: {
            31337: { address: "0xBCa9C82465387e36Eab7C7078AeDcE17Afcd2A22" },
            5: { address: "0xBCa9C82465387e36Eab7C7078AeDcE17Afcd2A22" },
            1: { address: "0x8359D1410447aD47955df4005063D92Fc456Fd58" }
        }
    },
];
export var getCollectionInfo = function (collectionName) {
    return SUPPORTED_COLLECTIONS.find(function (collectionInfo) { return collectionInfo.name === collectionName; });
};
export var OPENSEA_LINKS = {
    BASE_URLS: {
        31337: "https://testnets.opensea.io/assets",
        1: "https://opensea.io/assets",
        5: "https://testnets.opensea.io/assets"
    },
    getUrlForItem: function (chainId, collectionAddress, itemId) {
        var chainName = "ethereum";
        if (chainId === 31337 || chainId === 5) {
            chainName = "goerli";
        }
        // @ts-ignore
        return "".concat(OPENSEA_LINKS.BASE_URLS[chainId], "/").concat(chainName, "/").concat(collectionAddress, "/").concat(itemId);
    }
};
