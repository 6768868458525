import * as React from "react";
import {Grid} from "@mui/material";
import Twitter from "../../assets/Twitter.png";
import OpenSea from "../../assets/OpenSeaWhite.png";

const SOCIAL_MEDIA = [
  {image: Twitter, name: "Twitter", link: "https://twitter.com/bubblegumkids_"},
  {image: OpenSea, name: "Puppies", link: "https://opensea.io/collection/bubblegumpuppies"},
  {image: OpenSea, name: "Portal Passes", link: "https://opensea.io/collection/futuristic-portal-passes"}
];


function Footer() {

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-end"
      marginBottom="5%"
    >
      {SOCIAL_MEDIA.map((element) => {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            style={{textDecoration: "none", cursor: "pointer"}}
            key={element.name}
            href={element.link}
          >
            <Grid
              justifyContent="center"
              alignItems="center"
              color="white"
              item
              display="flex"
            >
              <Grid item>
                <img width={50} height={50} alt={element.name} src={element.image} />
              </Grid>
              <Grid item paddingLeft="10px">
                <p>{element.name}</p>
              </Grid>
            </Grid>
          </a>
        );
      })}
    </Grid>
  );
}
export default Footer;