import * as React from "react";
import {FC, useContext, useEffect, useState} from "react";

import {Alert, AlertTitle, Snackbar, Slide} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";
import {TransitionProps} from "@mui/material/transitions";

import "./Notification.scss";
import {NotificationContext} from "./NotificationProvider";

function SlideTransition(props: TransitionProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Slide {...props} direction="up"/>;
}

export const Notification: FC = () => {

  const [open, setOpen] = useState<boolean>(false);
  const {props} = useContext(NotificationContext);

  useEffect(() => {
    if(props.overlay) {
      setOpen(false);
      return;
    }
    setOpen(props.display);
  }, [props]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return(
    <Snackbar
      TransitionComponent={SlideTransition} open={open}
      anchorOrigin={{ vertical: "bottom", horizontal:"left" }}
      onClose={handleClose} autoHideDuration={props.autoHideDuration !== undefined ? props.autoHideDuration : 4000}
    >
      <Alert icon={props.icon ? props.icon : undefined} severity={props.alertType as AlertColor} className={"alert"}>
        { props.title && <AlertTitle>{props.title}</AlertTitle> }
        {props.message}
      </Alert>
    </Snackbar>
  );
};
