import React, {useEffect, useState} from "react";
import {MintingContext} from "../MintButton/MintingProvider";
import {AlchemyService} from "@bubblegum/commons/services/fetcher/Alchemy/AlchemyService";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {useNetwork} from "wagmi";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {CollectionItem} from "@bubblegum/commons/domain/CollectionItem";
import {Grid, Modal, Typography} from "@mui/material";
import {OPENSEA_LINKS, SUPPORTED_COLLECTIONS} from "@bubblegum/commons/services/fetcher/constants";
import {CollectionInfo} from "@bubblegum/commons/dist/services/fetcher/CollectionInfo";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  maxHeight: "300px",
  maxWidth: "600px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(56,5,55,0.33)",
  boxShadow: "24",
  pt: 2,
  px: 4,
  pb: 3,
};

export function MintedCollectionItem() {
  const [mintedItem, setMintedItem] = useState<CollectionItem>(undefined);
  const [collectionInfo, setCollectionInfo] = useState<CollectionInfo>(undefined);
  const [display, setDisplay] = useState<boolean>(false);

  const {props: mintingProps} = React.useContext(MintingContext);
  const { address } = useConnectedAddress();
  const { chain } = useNetwork();

  useEffect(() => {

    if(!mintingProps.tx) return;

    setCollectionInfo(
      SUPPORTED_COLLECTIONS.find(
        collectionInfo => collectionInfo.name === mintingProps.mintedCollectionName
      )
    );

    const alchemy = AlchemyService.createWithAllCollections(chain.id);

    alchemy.getCollectionItems(address, mintingProps.mintedCollectionName).then(items => {
      let lastItem: CollectionItem;

      items.forEach(item => {
        if(!lastItem) {
          lastItem = item;
        } else if(lastItem.blockchainId < item.blockchainId) {
          lastItem = item;
        }
      });
      setMintedItem(lastItem);
      setDisplay(true);
    });
  }, [mintingProps]);


  const renderText = () => {
    if(collectionInfo.name === CollectionName.PORTAL_PASSES) {
      return "Congratulations, this is your new Portal Pass";
    }

    else if(collectionInfo.name === CollectionName.FUTURISTIC_KIDS) {
      return "Congratulations, this is your new Futuristic Kid";
    }
  };

  const renderMedia = () => {
    if(collectionInfo.name === CollectionName.PORTAL_PASSES) {
      return <video height="225 px" src={mintedItem.image} autoPlay loop muted />;
    }
    return <img height="225 px" src={mintedItem.image} alt="Minted item" />;
  };

  if(!mintedItem) return;

  return <Modal
    open={display}
    onClose={() => setDisplay(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <div style={{...style, color: "white", position: "absolute"}}>
      <Grid container spacing="2" justifyItems="center" alignContent="center" alignItems="center" display="grid">
        <Grid item xs={12}>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontSize="1.3rem">
            {renderText()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {renderMedia()}
        </Grid>
        <Grid item xs={12}>
          <Typography id="modal-modal-title" variant="h6" component="h2" fontSize="1.3rem">
            <a
              href={OPENSEA_LINKS.getUrlForItem(chain.id, collectionInfo.contractInfo[chain.id].address, mintedItem.blockchainId)}
              target="_blank"
              rel="noreferrer"
              style={{textDecoration: "none", color: "white"}}
            >
              View on OpenSea
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  </Modal>;
}