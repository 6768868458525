import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ChooseCollectionItemCard} from "../../../../components/ChooseCollectionItemCard/ChooseCollectionItemCard";
import {useCollectionItemData} from "../../../../hooks/CollectionItemData";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {EmptyStateText} from "../../../../components/EmptyStateText/EmptyStateText";
import {SelectedItemProvider} from "../../../../components/SelectedItem/SelectedItemProvider";
import {useFuturisticPortalPassMinterContract} from "../../../../hooks/FuturisticPortalPassMinterContract";
import {useConnectedAddress} from "../../../../hooks/ConnectedAddress";
import {MintedCollectionItem} from "../../../../components/MintedCollectionItem/MintedCollectionItem";
import PuppiesFrame from "../../../../assets/PuppiesFrame.png";
import PuppiesFrameHover from "../../../../assets/PuppiesFrameHover.png";
import {PortalPassMintButton} from "../../../../components/MintButton/PortalPassMintButton";

export function PortalSection() {

  const {collectionItems} = useCollectionItemData(CollectionName.PUPPIES);
  const [isMintOpen, setIsMintOpen] = useState<boolean | undefined>(undefined);
  const {futuristicPortalPassMinterContract} = useFuturisticPortalPassMinterContract();
  const {address} = useConnectedAddress();

  useEffect(() => {
    if(!futuristicPortalPassMinterContract) {
      return;
    }
    futuristicPortalPassMinterContract.isMintOpen().then((open) => setIsMintOpen(open));

  }, [futuristicPortalPassMinterContract]);

  const renderChooseItems = () => {
    return <>
      <Grid
        container
        columns={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={10}
        rowSpacing={5}
      >
        {[1, 2, 3, 4, 5].map((i) => {
          return (
            <Grid key={i} item>
              <ChooseCollectionItemCard
                collectionItems={collectionItems}
                cardPosition={i}
                frameImage={PuppiesFrame}
                onHoverFrameImage={PuppiesFrameHover}
                selectToolTip="Select a Puppy"
              />
            </Grid>
          );
        })}
      </Grid>
      <Grid item style={{marginTop: "5%"}}>
        <PortalPassMintButton />
      </Grid>;
    </>;
  };

  const renderStates = () => {
    let text: string;
    if(!address) {
      // If the address is not connected
      text = "Please connect your wallet";
      return <EmptyStateText message={text}/>;

    } else if(isMintOpen === undefined) {
      // When collectionItems is undefined (it's loading)
      text = "Loading ...";
      return <EmptyStateText message={text} />;

    } else if (typeof isMintOpen === "boolean" && !isMintOpen) {
      // If mint is not open
      text = "Mint is not open";
      return <EmptyStateText message={text} />;

    } else if(collectionItems && collectionItems.length > 0) {
      return renderChooseItems();

    } else if(collectionItems && collectionItems.length === 0) {
      // The address do not have puppies
      text = "You don't have any puppies in this address";
      return <EmptyStateText message={text} />;

    }

    // By default, show loading
    text = "Loading ...";
    return <EmptyStateText message={text} />;


  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      direction="column"
    >
      <SelectedItemProvider>
        {renderStates()}
        <MintedCollectionItem />
      </SelectedItemProvider>
    </Grid>
  );
}
