import React, {FC, useEffect, useState} from "react";
import {ActionButton} from "../ActionButton/ActionButton";
import {NOTIFICATION_ACTIONS, NotificationContext} from "../Notification/NotificationProvider";
import {SELECTED_ITEM_ACTIONS, SelectedCollectionItem, SelectedItemContext} from "../SelectedItem/SelectedItemProvider";
import {withTransaction, WithTransactionProps} from "../../hoc/WithTransaction";
import {MINTING_ACTIONS, MintingContext} from "./MintingProvider";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {ContractTransaction} from "ethers";

interface MintButtonProps extends WithTransactionProps {
  initLoading?: boolean;
  amountOfSelectedItems: number;
  mintedCollectionName: CollectionName;
  successMessage: string;
  generateToolTipText(selectedItems: SelectedCollectionItem[]): string;
  onMint(selectedItems: SelectedCollectionItem[]): Promise<ContractTransaction>;
  areApproved(selectedItems: SelectedCollectionItem[]): Promise<boolean>;
  children?: React.ReactNode;
}


const BaseMintButton: FC<MintButtonProps> = ({
  submitTransaction,
  onMint,
  areApproved,
  children,
  initLoading,
  amountOfSelectedItems,
  mintedCollectionName,
  generateToolTipText,
  successMessage,
}) => {

  const [disableButton, setDisableButton] = useState<boolean>(true);
  const [tooltipText, setTooltipText] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(initLoading ?? false);

  const {selectedItems, dispatchSelectedItem} = React.useContext(SelectedItemContext);
  const {dispatchNotification} = React.useContext(NotificationContext);
  const {dispatchMinting} = React.useContext(MintingContext);

  async function mint() {
    if(disableButton || loading) {
      return;
    }

    setLoading(true);

    try {
      if(!await areApproved(selectedItems)) return;

      // TODO: What happens if it fails?
      const tx = await onMint(selectedItems);

      await submitTransaction(
        tx,
        dispatchNotification,
        successMessage
      );

      dispatchMinting({
        type: MINTING_ACTIONS.MINT,
        props: {
          tx: tx,
          burnedItems: selectedItems.map(selectedItem => selectedItem.item),
          mintedCollectionName,
        }
      });

      dispatchSelectedItem({
        type: SELECTED_ITEM_ACTIONS.CLEAN_SELECTION,
        item: undefined,
        position: 1
      });


    } catch (e) {
      console.log(e);
      dispatchNotification({
        type: NOTIFICATION_ACTIONS.SHOW,
        props: {
          alertType: "error",
          message: "Something went wrong, refresh and try again",
          display: true
        }
      });
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    if(selectedItems.length !== amountOfSelectedItems) {
      setDisableButton(true);
      setTooltipText(generateToolTipText(selectedItems));
      return;
    }

    setDisableButton(false);
    setTooltipText("");
  }, [selectedItems]);

  return (
    <>
      <ActionButton
        disabledToolTipText={tooltipText}
        disabled={disableButton}
        onClick={() => mint()}
        loading={loading}
      />
      {children}
    </>
  );
};

export const MintButton = withTransaction(BaseMintButton);