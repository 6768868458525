export var CollectionName;
(function (CollectionName) {
    CollectionName["PUPPIES"] = "Bubblegum Puppies";
    CollectionName["PORTAL_PASSES"] = "Portal Passes";
    CollectionName["KIDS"] = "Bubblegum Kids";
    CollectionName["FUTURISTIC_KIDS"] = "Futuristic Kids";
})(CollectionName || (CollectionName = {}));
var Collection = /** @class */ (function () {
    function Collection(name) {
        this._name = name;
    }
    Object.defineProperty(Collection.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: false,
        configurable: true
    });
    Collection.getAllCollectionNames = function () {
        return Object.keys(CollectionName);
    };
    Collection.getCollectionId = function (collectionName) {
        return Object.values(CollectionName).indexOf(collectionName);
    };
    return Collection;
}());
export { Collection };
