import React, {FC, useEffect} from "react";
import {CollectionItem} from "@bubblegum/commons/dist/domain/CollectionItem";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";

export interface SelectedCollectionItem {
    item: CollectionItem;
    position: number;
}

export interface SelectedItemContextType {
    selectedItems: SelectedCollectionItem[];
    dispatchSelectedItem: React.Dispatch<{ type: string; position: number; item: CollectionItem; }>
}

export const SelectedItemContext = React.createContext<SelectedItemContextType>({
  selectedItems: [],
  dispatchSelectedItem: undefined
});

export const SELECTED_ITEM_ACTIONS = {
  ITEM_SELECTED: "ITEM_SELECTED",
  CLEAN_SELECTION: "CLEAN_SELECTION"
};

export const SelectedItemReducer = (
  state: SelectedCollectionItem[],
  action: {type: string, position: number, item: CollectionItem}
): SelectedCollectionItem[] => {
  switch (action.type) {
  case SELECTED_ITEM_ACTIONS.ITEM_SELECTED:
    return [
      {position: action.position, item: action.item},
      ...state.filter(item => item.position !== action.position)
    ];
  case SELECTED_ITEM_ACTIONS.CLEAN_SELECTION:
    return [];
  }
};

interface SelectedItemProviderProps {
    children?: React.ReactNode
}

export const SelectedItemProvider: FC<SelectedItemProviderProps> = (props) => {

  const [selectedItems, dispatch] = React.useReducer(SelectedItemReducer, []);
  const {address} = useConnectedAddress();

  useEffect(() => {
    if(!address) return;

    dispatch({
      type: SELECTED_ITEM_ACTIONS.CLEAN_SELECTION,
      item: undefined,
      position: 0
    });

  }, [address]);

  return <SelectedItemContext.Provider
    value={{selectedItems: selectedItems, dispatchSelectedItem: dispatch}}
  >
    {props.children}
  </SelectedItemContext.Provider>;
};