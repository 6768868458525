import {useEffect, useState} from "react";
import {useSigner} from "wagmi";
import {FuturisticPortalPassMinter} from "@bubblegum/commons/contracts/FuturisticPortalPassMinter";

export function useFuturisticPortalPassMinterContract() {
  const {data: signer} = useSigner();
  const [
    futuristicPortalPassMinterContract,
    setFuturisticPortalPassMinterContract
  ] = useState<FuturisticPortalPassMinter>();

  useEffect(() => {
    if(!signer) return;

    FuturisticPortalPassMinter.getContract(signer).then(futuristicPortalPassMinterContract => {
      setFuturisticPortalPassMinterContract(futuristicPortalPassMinterContract);
    });

  }, [signer]);

  return {futuristicPortalPassMinterContract};

}