import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import {
  configureChains,
  createClient,
  WagmiConfig,
} from "wagmi";
import { hardhat, goerli, mainnet } from "@wagmi/core/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

// Solve the buffer problem in webpack 5.0
// https://solana.stackexchange.com/questions/212/uncaught-referenceerror-buffer-is-not-defined-using-phantom-wallet-solana-and
import * as buffer from "buffer";
import {NotificationProvider} from "./components/Notification/NotificationProvider";
window.Buffer = buffer.Buffer;

const defaultChains = [];

if(process.env.REACT_APP_ENV != "production") {
  // Add hardhat first for the development environment
  defaultChains.push(hardhat);
  defaultChains.push(goerli);
}

defaultChains.push(mainnet);

const { chains, provider } = configureChains(
  defaultChains,
  [
    alchemyProvider({ apiKey: "WhV3ilYCz5P6TvBcAzwwRzWf4ZmczCgH" }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: "Futuristic Kids",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider showRecentTransactions={true} chains={chains}>
        <NotificationProvider>
          <App />
        </NotificationProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
