import React, {FC, useState} from "react";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {SelectedCollectionItem, SelectedItemContext} from "../SelectedItem/SelectedItemProvider";
import {MintButton} from "./MintButton";
import {ContractTransaction} from "ethers";
import {CircularProgress, Modal, Step, StepLabel, Stepper, Typography} from "@mui/material";
import Continue from "../../assets/Continue.png";
import {withTransaction, WithTransactionProps} from "../../hoc/WithTransaction";
import {useFuturisticKidMinterContract} from "../../hooks/FuturisticKidMinterContract";
import {useFuturisticPortalPassesContract} from "../../hooks/FuturisticPortalPassesContract";
import {useKidsContract} from "../../hooks/KidsContract";
import {CollectionName} from "@bubblegum/commons/domain/Collection";

const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: "50%",
  left: "50%",
  maxHeight: "300px",
  maxWidth: "600px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(56,5,55,0.33)",
  boxShadow: "24",
  pt: 2,
  px: 4,
  pb: 3,
};


export const BaseFuturisticKidMintButton: FC<WithTransactionProps> = ({approveTransaction}) => {

  const [openApprovalModal, setOpenApprovalModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean; }>({});

  const {selectedItems} = React.useContext(SelectedItemContext);
  const {futuristicKidMinterContract} = useFuturisticKidMinterContract();
  const {futuristicPortalPassesContract: fppContract} = useFuturisticPortalPassesContract();
  const {kidsContract} = useKidsContract();
  const {address} = useConnectedAddress();


  const getIdsFromSelectedItems = (
    selectedItems: SelectedCollectionItem[]
  ): { passId: number, kidId: number } => {

    const pass = selectedItems.find((selectedItem) =>
      selectedItem.item.collection.name === CollectionName.PORTAL_PASSES
    );
    const kid = selectedItems.find((selectedItem) =>
      selectedItem.item.collection.name === CollectionName.KIDS
    );
    return {passId: pass?.item?.blockchainId, kidId: kid?.item?.blockchainId};

    // return {passId: 0, kidId: 0};
  };

  const onMint = async (selectedItems: SelectedCollectionItem[]): Promise<ContractTransaction> => {
    const {passId, kidId} = getIdsFromSelectedItems(selectedItems);
    return await futuristicKidMinterContract.mint(passId, kidId);
  };

  const areApproved = async (selectedItems: SelectedCollectionItem[]): Promise<boolean> => {
    const operator = futuristicKidMinterContract.getAddress();
    const {passId, kidId} = getIdsFromSelectedItems(selectedItems);

    let isOneApproveMissing = false;

    // Check the pass
    let isApprovedForAll = await fppContract.isApprovedForAll(address, operator);
    let isApprovedForToken = (await fppContract.getApproved(passId.toString())) === operator;

    if (!isApprovedForAll && !isApprovedForToken) {
      setOpenApprovalModal(true);
      setActiveStep(1);
      isOneApproveMissing = true;
    } else {
      handleComplete(1);
    }

    isApprovedForAll = await kidsContract.isApprovedForAll(address, operator);
    isApprovedForToken = (await kidsContract.getApproved(kidId.toString())) === operator;
    // Check the kids
    if (!isApprovedForAll && !isApprovedForToken) {
      setOpenApprovalModal(true);
      setActiveStep(0);
      isOneApproveMissing = true;
    } else {
      handleComplete(0);
    }

    return !isOneApproveMissing;
  };

  const handleComplete = (step: number) => {
    const newCompleted = completed;
    newCompleted[step] = true;
    setCompleted(newCompleted);
  };


  async function afterApproveFinished(step: number) {
    setLoading(false);
    if (await areApproved(selectedItems)) {
      setOpenApprovalModal(false);
    } else {
      setOpenApprovalModal(true);
    }
    handleComplete(step);
  }

  const approvePortalPass = async () => {
    await approveTransaction(
      futuristicKidMinterContract.getAddress(),
      fppContract,
      () => {setOpenApprovalModal(false);setLoading(true);},
      () => afterApproveFinished(1),
      "You approved your Futuristic Portal Passes"
    );
  };

  const approveBGKids = async () => {
    await approveTransaction(
      futuristicKidMinterContract.getAddress(),
      kidsContract,
      () => {setOpenApprovalModal(false);setLoading(true);},
      () => afterApproveFinished(0),
      "You approve your Futuristic Bubblegum Kids"
    );
  };

  const generateToolTipText = (selectedItems: SelectedCollectionItem[]): string => {
    const {passId, kidId} = getIdsFromSelectedItems(selectedItems);

    if (!passId && kidId) {
      return "Select one Futuristic Portal Pass and a Kid to get a Futuristic Kid";
    }

    if (!passId) {
      return "Select one Futuristic Portal Pass to get a Futuristic Kid";
    }

    if (!kidId) {
      return "Select one Kid to get a Futuristic Kid";
    }
  };

  function renderButton() {
    if (loading) {
      return <CircularProgress/>;
    }

    return <img
      style={{cursor: "pointer"}}
      alt="Continue"
      onClick={activeStep === 0 ? approveBGKids : approvePortalPass}
      width={300}
      src={Continue}
    />;
  }
  return (
    <MintButton
      onMint={onMint}
      areApproved={areApproved}
      initialLoading={loading}
      generateToolTipText={generateToolTipText}
      amountOfSelectedItems={2}
      mintedCollectionName={CollectionName.FUTURISTIC_KIDS}
      successMessage="Futuristic Kid minted successfully!"
    >
      <Modal
        open={openApprovalModal}
        onClose={() => {
          setOpenApprovalModal(false);
          setLoading(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={{...style, color: "white", position: "absolute"}}>
          <div style={{position: "relative", display: "block"}}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {["Approve Bubblegum Kids", "Approve Portal Passes", "Mint"].map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepLabel>
                    <Typography color="white">{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h1" fontSize="2rem">
                  You need to approve the contract to burn your {activeStep === 0 ? "Bubblegum Kid" : "Portal Pass"}
              </Typography>
            </div>
            <div style={{float: "right"}}>
              {renderButton()}
            </div>
          </div>
        </div>
      </Modal>
    </MintButton>
  );
};


export const FuturisticKidMintButton = withTransaction(BaseFuturisticKidMintButton);