import {useEffect, useState} from "react";
import {useSigner} from "wagmi";
import {Puppies} from "@bubblegum/commons/contracts/Puppies";

export function usePuppiesContract() {
  const {data: signer} = useSigner();
  const [puppiesContract, setPuppiesContract] = useState<Puppies>();

  useEffect(() => {
    if(!signer) return;

    Puppies.getContract(signer).then(puppiesContract => {
      setPuppiesContract(puppiesContract);
    });

  }, [signer]);

  return {puppiesContract};

}