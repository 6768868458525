import React, {FC, useEffect} from "react";
import {ContractTransaction} from "ethers";
import {CollectionItem} from "@bubblegum/commons/dist/domain/CollectionItem";
import {useConnectedAddress} from "../../hooks/ConnectedAddress";
import {CollectionName} from "@bubblegum/commons/dist/domain/Collection";

export interface MintingContextType {
    props?: MintingProps;
    dispatchMinting: React.Dispatch<{type: string, props: MintingProps}>
}

export const MintingContext = React.createContext<MintingContextType>({
  props: undefined,
  dispatchMinting: undefined
});

export const MINTING_ACTIONS = {
  MINT: "MINT",
  CLEAN: "CLEAN"
};

export interface MintingProps {
    tx?: ContractTransaction;
    burnedItems: CollectionItem[]
    mintedCollectionName?: CollectionName;
}

export const MintingReducer = (
  state: MintingProps,
  action: {type: string, props: MintingProps}
): MintingProps => {
  switch (action.type) {
  case MINTING_ACTIONS.MINT:
    return {...action.props};
  case MINTING_ACTIONS.CLEAN:
    return {burnedItems: []};
  }
};

interface MintingProviderProps {
    children?: React.ReactNode
}

export const MintingProvider: FC<MintingProviderProps> = (props) => {

  const [mintingProps, dispatchMinting] = React.useReducer(MintingReducer, {burnedItems: []});
  const {address} = useConnectedAddress();

  useEffect(() => {
    if(!address) return;

    dispatchMinting({
      type: MINTING_ACTIONS.CLEAN,
      props: {
        burnedItems: []
      }
    });

  }, [address]);

  return <MintingContext.Provider
    value={{props: mintingProps, dispatchMinting}}
  >
    {props.children}
  </MintingContext.Provider>;
};