import {useEffect, useState} from "react";
import {useSigner} from "wagmi";
import {FuturisticKidMinter} from "@bubblegum/commons/contracts/FuturisticKidMinter";

export function useFuturisticKidMinterContract() {
  const {data: signer} = useSigner();
  const [
    futuristicKidMinterContract,
    setFuturisticKidMinterContract
  ] = useState<FuturisticKidMinter>();

  useEffect(() => {
    if(!signer) return;

    FuturisticKidMinter.getContract(signer).then(puppiesContract => {
      setFuturisticKidMinterContract(puppiesContract);
    });

  }, [signer]);

  return {futuristicKidMinterContract};

}