import React from "react";
import {
  NOTIFICATION_ACTIONS, NotificationContext,
  NotificationProps
} from "../components/Notification/NotificationProvider";
import {Typography} from "@mui/material";
import {ContractTransaction} from "ethers";

import {useAddRecentTransaction} from "@rainbow-me/rainbowkit";
import {useNetwork} from "wagmi";
import {ApprovalContract} from "@bubblegum/commons/contracts/ApprovalContract";

export interface WithTransactionProps {
    submitTransaction(
        tx: ContractTransaction,
        dispatchNotification: React.Dispatch<{type: string, props: NotificationProps}>,
        successMessage: string,
    ): Promise<void>
    approveTransaction(
        operator: string,
        contract: ApprovalContract<any>,
        afterTransactionIsSent: () => void,
        finallyFunction: () => void,
        successMessage: string,
    ): Promise<void>
}

const NUMBER_OF_CONFIRMATIONS = 2;

export const withTransaction = (ComponentWithTransactions: React.FC) => {

  const chainMap: {[key in number]: string} = {
    31337: "https://etherscan.io/tx/",
    1: "https://etherscan.io/tx/",
    5: "https://goerli.etherscan.io/tx/"
  };


  function NewComponent(props: any) {
    const addRecentTransaction = useAddRecentTransaction();
    const { chain } = useNetwork();
    const {dispatchNotification} = React.useContext(NotificationContext);

    async function approveTransaction(
      operator: string,
      contract: ApprovalContract<any>,
      afterTransactionIsSent: () => void,
      finallyFunction: () => void,
      successMessage: string,
    ): Promise<void> {

      try {
        const tx = await  contract.setApprovalForAll(operator);

        afterTransactionIsSent();

        await submitTransaction(
          tx,
          dispatchNotification,
          successMessage
        );
      }
      catch(error: unknown){
        console.log(error);
        dispatchNotification({
          type: NOTIFICATION_ACTIONS.SHOW,
          props: {
            alertType: "error",
            message: "Something happened while trying to approve tokens",
            display: true,
          }
        });
      }
      finally {
        finallyFunction();
      }
    }

    async function submitTransaction(
      tx: ContractTransaction,
      dispatchNotification: React.Dispatch<{type: string, props: NotificationProps}>,
      successMessage: string,
    ) {

      addRecentTransaction({
        hash: tx.hash,
        description: "Approval",
        confirmations: NUMBER_OF_CONFIRMATIONS,
      });

      dispatchNotification({
        type: NOTIFICATION_ACTIONS.SHOW,
        props: {
          alertType: "info",
          message: "",
          display: true,
          autoHideDuration: null,
          icon: <>
            <Typography>
              Transaction submitted, waiting for it to be confirmed!
            </Typography>
            <Typography onClick={() => window.open(
              `${chainMap[chain.id]}${tx.hash}`,
              "_blank"
            ).focus()}
            style={{cursor: "pointer"}}
            >
              Check it on etherscan
            </Typography>
          </>,
          overlay: true
        }
      });

      await tx.wait(NUMBER_OF_CONFIRMATIONS);

      dispatchNotification({
        type: NOTIFICATION_ACTIONS.SHOW,
        props: {
          alertType: "success",
          message: successMessage,
          display: true
        }
      });
    }

    //render OriginalComponent and pass on its props.
    return <ComponentWithTransactions
      {...props}
      submitTransaction={submitTransaction}
      approveTransaction={approveTransaction}
    />;
  }
  return NewComponent;
};
