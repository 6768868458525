import * as React from "react";
import {CircularProgress, Grid, Tooltip} from "@mui/material";

import "./ActionButton.scss";

import {FC} from "react";
import {ActionButtonInterface} from "./ActionButtonInterface";
import EnterThePortalButton from "../../assets/EnterThePortalButton.png";
import EnterThePortalButtonHover from "../../assets/EnterThePortalButtonHover.png";

export const ActionButton: FC<ActionButtonInterface> = (actionButtonInterface) => {
  const {
    disabledToolTipText,
    onClick,
    loading
  } = actionButtonInterface;

  function renderButton() {
    if(loading) {
      return <CircularProgress />;
    }

    return <img
      width="300"
      height="75"
      src={EnterThePortalButton}
      onMouseOver={e => (e.currentTarget.src = EnterThePortalButtonHover)}
      onMouseOut={e => (e.currentTarget.src = EnterThePortalButton)}
      onClick={() => onClick()}
      alt="Mint Portal Pass"
      style={{cursor: "pointer"}}
    />;
  }

  return (
    <Grid container>
      <Grid container>
        <Tooltip title={disabledToolTipText} arrow>
          <span>
            {renderButton()}
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
