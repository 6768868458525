import React from "react";
import {Grid} from "@mui/material";

import "./styles/PassPortal.scss";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import {PortalSection} from "./sections/PortalSection/PortalSection";
import {MintingProvider} from "../../components/MintButton/MintingProvider";
import {SUPPORTED_COLLECTIONS} from "@bubblegum/commons/services/fetcher/constants";
import {CollectionName} from "@bubblegum/commons/domain/Collection";

export function PassPortal() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ minHeight: "100vh" }}
    >
      <Grid >
        <NavBar collectionInfo={SUPPORTED_COLLECTIONS.find(collectionInfo => collectionInfo.name === CollectionName.PORTAL_PASSES)} />
      </Grid>
      <Grid>
        <MintingProvider>
          <PortalSection />
        </MintingProvider>
      </Grid>
      <Grid >
        <Footer />
      </Grid>
    </Grid>
  );
}