import React from "react";
import {Grid} from "@mui/material";

import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import {FuturisticKidSection} from "./sections/FuturisticKidSection/FuturisticKidSection";
import {MintingProvider} from "../../components/MintButton/MintingProvider";
import {SUPPORTED_COLLECTIONS} from "@bubblegum/commons/services/fetcher/constants";
import {CollectionName} from "@bubblegum/commons/domain/Collection";

export function FuturisticKid() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ minHeight: "100vh" }}
    >
      <Grid >
        <NavBar collectionInfo={SUPPORTED_COLLECTIONS.find(collectionInfo => collectionInfo.name === CollectionName.FUTURISTIC_KIDS)} />
      </Grid>
      <Grid>
        <MintingProvider>
          <FuturisticKidSection />
        </MintingProvider>
      </Grid>
      <Grid >
        <Footer />
      </Grid>
    </Grid>
  );
}