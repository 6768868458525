import React, {useEffect, useState} from "react";
import {CollectionItem} from "@bubblegum/commons/domain/CollectionItem";
import {useConnectedAddress} from "./ConnectedAddress";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {AlchemyService} from "@bubblegum/commons/services/fetcher/Alchemy/AlchemyService";
import {MintingContext} from "../components/MintButton/MintingProvider";
import {useNetwork} from "wagmi";

export function useCollectionItemData(collectionName: CollectionName) {
  const { address } = useConnectedAddress();
  const { chain } = useNetwork();


  const [collectionItemData, setCollectionItemData] = useState<CollectionItem[]>(undefined);
  const {props: {tx, burnedItems}} = React.useContext(MintingContext);

  useEffect(() => {
    if(!address || !chain) {
      setCollectionItemData(undefined);
      return;
    }

    if(tx) {
      setCollectionItemData(
        collectionItemData.filter(item => !burnedItems.find(
          (mintedItem) => item.id === mintedItem.id)
        )
      );
      return;
    }

    async function fetchCollectionItemData() {
      const alchemy = AlchemyService.createWithAllCollections(chain.id);
      setCollectionItemData(await alchemy.getCollectionItems(address, collectionName));
      // setCollectionItemData(await alchemy.getCollectionItems(
      //   "0x896273686b238D0281d8B93DA78e84Bf35E1AC23",
      //   collectionName
      // ));

      // const collectionFetcher = RawOpenSeaCollectionFetcher.createWithAllCollections();
      // setCollectionItemData(await collectionFetcher.getCollectionItems(address, collectionName));
    }

    fetchCollectionItemData();
  }, [address, tx, burnedItems, chain]);
  return {collectionItems: collectionItemData};
}