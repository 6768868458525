import {useEffect, useState} from "react";
import {useSigner} from "wagmi";
import {BubblegumKids} from "@bubblegum/commons/contracts/BubblegumKids";

export function useKidsContract() {
  const {data: signer} = useSigner();
  const [kidsContract, setKidsContract] = useState<BubblegumKids>();

  useEffect(() => {
    if(!signer) return;

    BubblegumKids.getContract(signer).then(kidsContract => {
      setKidsContract(kidsContract);
    });

  }, [signer]);

  return {kidsContract};

}