import React, {FC} from "react";
import {Notification} from "./Notification";
import LoadingOverlay from "react-loading-overlay-ts";

export interface NotificationContextType {
    props: NotificationProps;
    dispatchNotification: React.Dispatch<{type: string, props: NotificationProps}>
}

const EMPTY_NOTIFICATION = {display: false, message: "", alertType: "info"};

export const NotificationContext = React.createContext<NotificationContextType>({
  props: EMPTY_NOTIFICATION,
  dispatchNotification: undefined
});

export const NOTIFICATION_ACTIONS = {
  SHOW: "SHOW"
};

export interface NotificationProps {
    alertType: string;
    title?: string;
    message: string;
    display: boolean;
    autoHideDuration?: number;
    icon?: React.ReactNode;
    overlay?: boolean
}

export const NotificationReducer = (
  state: NotificationProps,
  action: {type: string, props: NotificationProps}
): NotificationProps => {
  switch (action.type) {
  case NOTIFICATION_ACTIONS.SHOW:
    return {...action.props};
  }
};

interface NotificationProviderProps {
    children?: React.ReactNode
}

export const NotificationProvider: FC<NotificationProviderProps> = (props) => {

  const [notificationProps, dispatchNotification] = React.useReducer(NotificationReducer, EMPTY_NOTIFICATION);

  return <NotificationContext.Provider
    value={{props: notificationProps, dispatchNotification}}
  >
    <LoadingOverlay
      active={notificationProps.overlay}
      spinner
      text={notificationProps.icon ? notificationProps.icon : notificationProps.message}
    >
      {props.children}
      <Notification />
    </LoadingOverlay>
  </NotificationContext.Provider>;
};