import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {CardActionArea, Grid} from "@mui/material";

import "./CollectionItemCard.scss";
import {CardCollectionItemInterface} from "./CardCollectionItemInterface";
import {FC, useEffect, useState} from "react";
import OpenSeaIcon from "../../assets/OpenSea.png";
import {LazyLoadComponent, LazyLoadImage} from "react-lazy-load-image-component";
import {OPENSEA_LINKS, SUPPORTED_COLLECTIONS} from "@bubblegum/commons/services/fetcher/constants";
import {useNetwork} from "wagmi";
import {CollectionInfo} from "@bubblegum/commons/services/fetcher/CollectionInfo";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {CollectionItem} from "@bubblegum/commons/dist/domain/CollectionItem";

export const CollectionItemCard: FC<CardCollectionItemInterface> = ({
  collectionItem, 
  onClickCardFunction, 
  collectionItemsSelected,
}) => {

  const [selected, setSelected] = useState<boolean>(false);
  const [collectionInfo, setCollectionInfo] = useState<CollectionInfo>(undefined);
  const {chain} = useNetwork();

  const onClick = (): void => {
    if(onClickCardFunction(collectionItem)) {
      setSelected(!selected);
    }
  };

  useEffect(() => {
    setCollectionInfo(SUPPORTED_COLLECTIONS.find(
      collectionInfo => collectionInfo.name === collectionItem.collection.name
    ));
  }, [collectionItem]);

  useEffect(() => {
    function isSelected(
      selectedCollectionItem: CollectionItem,
    ) {
      // TODO: This should be done via an equals
      return collectionItem.id === selectedCollectionItem.id &&
          collectionItem.collection.name === selectedCollectionItem.collection.name ;
    }

    setSelected(collectionItemsSelected.some(isSelected));
  }, [collectionItemsSelected]);

  const parseIPFSUrl = (cidUrl: string) => {
    return `https://ipfs.io/${cidUrl.replace("://", "/")}`;
  };

  const imageUrl = collectionItem.image.includes("ipfs") ?
    parseIPFSUrl(collectionItem.image) :
    collectionItem.image;

  const onSelected = () => {
    if(!selected) {
      onClick();
    }
  };

  const displayMedia = () => {
    if(!imageUrl) return;

    // TODO: Change for an isVideo prop
    if(collectionItem.collection.name === CollectionName.PORTAL_PASSES) {
      return <LazyLoadComponent>
        <video height={225} src={imageUrl} style={{objectFit: "cover", maxWidth: "100%"}} />
      </LazyLoadComponent>;
    }

    return <LazyLoadImage height={225} src={imageUrl}/>;
  };

  if(!collectionInfo) return;

  return (
    <Card className={selected ? "card selected" : "card"} onClick={() => onSelected()}>
      <CardActionArea>
        <CardMedia
          sx={{ height: 225 }}
        >
          {displayMedia()}
        </CardMedia>
        <CardContent className={selected ? "card-background background-selected" : "card-background"}>
          <Grid className={"item-number"} xs={12} sm={12}>
            {collectionItem.name}
          </Grid>
        </CardContent>
        <a
          href={OPENSEA_LINKS.getUrlForItem(
            chain.id, collectionInfo.contractInfo[chain.id].address,
            collectionItem.blockchainId
          )}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <img alt="Open Sea link" src={OpenSeaIcon} width="24" style={{position: "absolute", top: 5, right: 5}} />
        </a>

      </CardActionArea>
    </Card>
  );
};
