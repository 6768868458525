import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ChooseCollectionItemCard} from "../../../../components/ChooseCollectionItemCard/ChooseCollectionItemCard";
import {useCollectionItemData} from "../../../../hooks/CollectionItemData";
import {CollectionName} from "@bubblegum/commons/domain/Collection";
import {EmptyStateText} from "../../../../components/EmptyStateText/EmptyStateText";
import {SelectedItemProvider} from "../../../../components/SelectedItem/SelectedItemProvider";
import {useFuturisticPortalPassMinterContract} from "../../../../hooks/FuturisticPortalPassMinterContract";
import {useConnectedAddress} from "../../../../hooks/ConnectedAddress";
import {MintedCollectionItem} from "../../../../components/MintedCollectionItem/MintedCollectionItem";
import BGKFrame from "../../../../assets/BGKFrame.png";
import BGKFrameHover from "../../../../assets/BGKFrameHover.png";
import {FuturisticKidMintButton} from "../../../../components/MintButton/FuturisticKidMintButton";

export function FuturisticKidSection() {

  const {collectionItems: portalCollectionItems} = useCollectionItemData(CollectionName.PORTAL_PASSES);
  const {collectionItems: kidsCollectionItem} = useCollectionItemData(CollectionName.KIDS);
  const [isMintOpen, setIsMintOpen] = useState<boolean | undefined>(undefined);
  const {futuristicPortalPassMinterContract} = useFuturisticPortalPassMinterContract();
  const {address} = useConnectedAddress();

  useEffect(() => {
    if(!futuristicPortalPassMinterContract) {
      return;
    }
    futuristicPortalPassMinterContract.isMintOpen().then((open) => setIsMintOpen(open));

  }, [futuristicPortalPassMinterContract]);

  const renderChooseItems = () => {
    return <>
      <Grid
        container
        columns={5}
        direction="row"
        justifyContent="center"
        alignItems="center"
        columnSpacing={10}
        rowSpacing={5}
      >
        <Grid item>
          <ChooseCollectionItemCard
            collectionItems={portalCollectionItems}
            cardPosition={1}
            frameImage={BGKFrame}
            onHoverFrameImage={BGKFrameHover}
            chooseFirstItem={true}
            selectToolTip=""
          />
        </Grid>
        <Grid item>
          <ChooseCollectionItemCard
            collectionItems={kidsCollectionItem}
            cardPosition={2}
            frameImage={BGKFrame}
            onHoverFrameImage={BGKFrameHover}
            selectToolTip="Choose a BGK Kid"
          />
        </Grid>
      </Grid>
      <Grid item style={{marginTop: "5%"}}>
        <FuturisticKidMintButton />
      </Grid>;
    </>;
  };

  const renderStates = () => {
    let text: string;
    if(!address) {
      // If the address is not connected
      text = "Please connect your wallet";
      return <EmptyStateText message={text}/>;

    } else if(isMintOpen === undefined) {
      // When collectionItems is undefined (it's loading)
      text = "Loading ...";
      return <EmptyStateText message={text} />;

    } else if (typeof isMintOpen === "boolean" && !isMintOpen) {
      // If mint is not open
      text = "Mint is not open";
      return <EmptyStateText message={text} />;

    } else if(
      kidsCollectionItem &&
        kidsCollectionItem.length > 0 &&
        portalCollectionItems &&
        portalCollectionItems.length > 0
    ) {
      return renderChooseItems();

    } else if(kidsCollectionItem && portalCollectionItems) {
      text = "";
      // The address do not have puppies
      if(kidsCollectionItem.length === 0) {
        text = "You don't have any bubblegum kids in this address";
      }

      if(portalCollectionItems.length === 0) {
        text = "You don't have any portal passes in this address";
      }

      if(portalCollectionItems.length === 0 && kidsCollectionItem.length === 0) {
        text = "You don't have any portal passes or bubblegum kids in this address";
      }

      if(text) {
        return <EmptyStateText message={text} />;
      }
    }

    // By default, show loading
    text = "Loading ...";
    return <EmptyStateText message={text} />;


  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-around"
      direction="column"
    >
      <SelectedItemProvider>
        {renderStates()}
        <MintedCollectionItem />
      </SelectedItemProvider>
    </Grid>
  );
}
